.stakeTextColor {
    line-height: 70px;
    font-size: 40px;
    color: #16225D;
}

.bg-stakeBg {
    background-image: url(/public/assets/dashboard2nd.png);
    background-repeat: no-repeat;
    background-size: unset;
    background-position: right;
    margin-top: -220px;
}

.text-grad {
    background: linear-gradient(92.18deg, #0D86D7 -2.82%, #D24BB0 99.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* color: linear-gradient(92.18deg, #0D86D7 -2.82%, #D24BB0 99.39%) !important; */
}

.staking-text {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 81px;
    color: black;
}

.sizeOfCard {
    min-width: 500px;
}

@media only screen and (max-width: 650px) {
    .sizeOfCard {
        min-width: 1px;
        width: 100%;
    }
}