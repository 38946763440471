.titleRound {
    min-width: 600px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg-dHome {
    background-image: url(/public/assets/dashboard1st.png);
    background-repeat: no-repeat;
    background-size: unset;
    margin-top: -220px;
    background-color: #F3F6F9;
}

/* .border-parent {

    border-radius: 10px;
    background: linear-gradient(92.18deg, #0D86D7 -2.82%, #D24BB0 99.39%);
} */


.cardMaker {
    height: 169px;
    width: 214px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    border: 3px solid transparent;
    border-image-slice: 1;
    border-image-source: linear-gradient(92.18deg, #0D86D7 -2.82%, #D24BB0 99.39%);
    -webkit-border-image-source: linear-gradient(92.18deg, #0D86D7 -2.82%, #D24BB0 99.39%);
    /* Define the gradient */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.cardMaker:hover {
    border-image-slice: 1;
    border-image-source: linear-gradient(92.18deg, #029EE3 100%, #C045AE 99.39%);
    -webkit-border-image-source: linear-gradient(92.18deg, #029EE3 100%, #C045AE 99.39%);
    border-radius: 16px;
}

.font-bolding {
    font-weight: 900 !important;
}

@media only screen and (min-width: 430px) and (max-width: 600px) {
    .titleRound {
        min-width: 450px;
        height: 53px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px !important;
    }

    .cardMaker {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media only screen and (max-width: 429px) {
    .titleRound {
        min-width: 350px;
        height: 53px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 9px !important;
    }

    .cardMaker {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}