.dHeader {
    /* background-color: #16225D; */
    background: transparent;
    height: 100px;
}

.navcss {
    text-decoration: none;
    display: inline-block;
    color: black;
    font-weight: 500;
    /* color: rgba(0, 0, 0, 0.6); */
    fill: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    font-size: 0.95rem;
}