.bg-footer {
    background-image: url(/public/assets/dashboard1st.png);
    background-repeat: no-repeat;
    background-size: unset;
    background-color: #fff8ff;
    /* background-size: initial; */

}

.textWidth {
    width: 50%;
}

@media only screen and (max-width: 650px) {
    .textWidth {
        width: 80%;
    }
}