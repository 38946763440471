.navbarFont {
    font-style: normal;
    font-weight: 700;
    font-size: 33px !important;
    color: black;
    line-height: 52px;
    /* word-spacing: 2rem !important; */
}

.for-logo-width {}

.logo-width {
    /* width: 30%; */
}

.logo-width2 {
    width: 25%;
}

@media only screen and (max-width: 769px) {
    .logo-width {
        /* width: 80%; */
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .for-logo-width {
        width: 60%;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .logo-width2 {
        width: 60%;
    }
}