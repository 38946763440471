.manageTable {
    width: auto;
    height: 130px;
    background: #E6EDFA;
    margin-top: 50px;
    border-radius: 8px;
    /* overflow-x: scroll; */
}

.gridding {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    height: 130px;
    width: 100%;
    overflow: hidden;
    align-items: center;
    gap: 10px;
}

@media only screen and (max-width: 1400px) {
    .gridding {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        height: 130px;
        width: 100%;
        overflow-x: scroll;
        align-items: center;
        gap: 0px;
    }

    .itemGrid {
        width: 140px;
    }

}