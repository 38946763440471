.sc {
    font-family: 'Darker Grotesque';
    font-style: normal;
    color: black;
}

.bg-AffiliateUser {
    /* background-image: url(/public/assets/referralbg.png);
    background-repeat: no-repeat;
    background-size: cover; */

    position: relative;
    padding: 10px 0px 100px 0px;
    width: 100%;
    padding-bottom: 150px;
    background-color: transparent;
    overflow: hidden;

}

.bg-AffiliateUser::after {

    content: '';
    position: absolute;
    top: -50px;
    /* Initial position outside the banner */
    left: -50px;
    /* Initial position outside the banner */
    /* transform: rotate(-89.6deg); */
    transform: translate(-50%, -50%);
    width: 1206px;
    height: 1307px;
    border-radius: 50%;
    background: linear-gradient(180deg, rgba(36, 0, 255, 0.5) 0%, rgba(4, 152, 224, 0.5) 0.01%, rgba(209, 74, 176, 0.5) 100%);
    filter: blur(100px);
    /* box-shadow: 0 0 100px 100px rgba(0, 0, 0, 0.5); */
    animation: moveCircleAffiliate 10s linear infinite;
    z-index: -1;

    /* Move the circle to the background */
    ;
}

@keyframes moveCircleAffiliate {
    0% {
        top: -50px;
        /* Initial position outside the banner */
        left: -50px;
        /* Initial position outside the banner */
    }

    25% {
        top: -50px;
        /* Move upwards */
        left: calc(50% - 50px);
        /* Center horizontally */
    }

    50% {
        top: calc(50% - 50px);
        /* Center vertically */
        left: calc(100% - 50px);
        /* Move to the right */
    }

    75% {
        top: calc(100% - 50px);
        /* Move downwards */
        left: calc(50% - 50px);
        /* Center horizontally */
    }

    100% {
        top: calc(50% - 50px);
        /* Center vertically */
        left: -50px;
        /* Move to the left */
    }
}


.affSemiTitle {

    font-family: 'Poppins';
    color: #2038af !important;
    font-weight: 700 !important;
}

.color-grad-affiliate {
    background: linear-gradient(91.8deg, #066A98 17.31%, #BB07A0 84.27%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.cardOfAffiliate {
    width: 284px;
    height: 216px;
    background: #DAF0FF;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.circle1 {
    z-index: 1;
    margin-left: -440px;

}

.circle2 {
    z-index: 1;
    margin-right: -440px;
}

.pdfBtn2 {
    width: 140px;
    height: 40px;
    background: linear-gradient(92.18deg, #0D86D7 -2.82%, #D24BB0 99.39%);
    box-shadow: 4px 4px 20px #7A66C2;
    border-radius: 8px;
    border: transparent !important;
    text-decoration: underline;
}

.pdfBtn2:hover {

    background: linear-gradient(92.18deg, #0675be -2.82%, #b63696 99.39%);
}

@media only screen and (max-width: 440px) {

    .circle1 {
        z-index: 1;
        margin-left: -325px;

    }

    .affSemiTitle {
        font-size: 14px;
    }

    .circle2 {
        z-index: 1;
        margin-right: -325px;
    }
}

@media only screen and (max-width: 990px) {
    .cardOfAffiliate {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}