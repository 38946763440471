.bg-handle-with-text {
    background: #DAF0FF !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;

}

.imgRespo {
    margin-top: -50px;
}

@media only screen and (max-width: 650px) {
    .imgRespo {
        width: 25%;
        margin-top: -30px;
    }

}