body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F3F6F9 !important;
  font-size: 1.1rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-family: 'Poppins', sans-serif;
}

.btn-close {
  /* filter: invert(1) grayscale(100%) brightness(200%) !important; */
}

.navbar-toggler {
  background-color: #F3F6F9 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  /* color: white !important; */
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  /* color: white !important; */
}

/* .css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
  border: 0px !important;
  display: none !important;
} */

/* p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-weight: 600 !important;
} */