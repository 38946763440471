.bg-SuperDapps {
    background-image: url(/public/assets/dashboard1st.png);
    background-repeat: no-repeat;
    background-size: unset;
    /* background-position: center; */
    height: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F3F6F9;
}

.controlledMargin {
    position: relative;
    top: -140px;
}

.tempSpace {
    margin-top: 150px;
}

.cardDapps {
    height: 350px;
    /* width: 300px; */
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    border: 3px solid transparent;
    border-image-slice: 1;
    border-image-source: linear-gradient(92.18deg, #0D86D7 -2.82%, #D24BB0 99.39%);
    -webkit-border-image-source: linear-gradient(92.18deg, #0D86D7 -2.82%, #D24BB0 99.39%);
    /* Define the gradient */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.cardDapps:hover {
    border-image-slice: 1;
    border-image-source: linear-gradient(92.18deg, #029EE3 100%, #C045AE 99.39%);
    -webkit-border-image-source: linear-gradient(92.18deg, #029EE3 100%, #C045AE 99.39%);
    /* border-radius: 16px; */
    box-shadow: 0 0 20px 20px #c04daf31;
    transition: 0.3s box-shadow;
}

/* .cardDapps img:hover {
    border: 3px solid #C045AE;
} */

@media only screen and (min-width: 766px) and (max-width: 990px) {
    .tempSpace {
        margin-top: 200px;
        margin-bottom: 100px;
    }

    .controlledMargin {
        position: relative;
        top: 0px;
        margin-bottom: 50px;
    }

    .bg-SuperDapps {
        height: 900px;
    }

    .cardDapps {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@media only screen and (max-width: 765px) {
    .tempSpace {
        margin-top: 200px;
        margin-bottom: 250px;
    }

    .controlledMargin {
        position: relative;
        top: 0px;
        margin-bottom: 50px;
    }

    .bg-SuperDapps {
        height: 900px;
    }

    .cardDapps {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}