.pdfBtn {
    width: 140px;
    height: 40px;
    background: linear-gradient(92.18deg, #0D86D7 -2.82%, #D24BB0 99.39%);
    box-shadow: 4px 4px 20px #7A66C2;
    border-radius: 8px;
    border: transparent !important;
    text-decoration: underline;
}

.pdfBtn:hover {

    background: linear-gradient(92.18deg, #0675be -2.82%, #b63696 99.39%);
}

.iframeSize{
    width: 350px;
    height: 250px !important
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .iframeSize{
        width: 300px;
        height: 250px !important;
    }
}
@media only screen and (min-width: 371px) and (max-width: 991px) {
    .iframeSize{
        width: 300px;
        height: 200px !important;
    }
}


@media only screen and (max-width: 370px) {
    .iframeSize{
        width: auto;
        height: 200px !important;
    }
}