.bg-referral {
    /* background-image: url(/public/assets/referralbg.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    margin-top: -210px; */
    /* margin-top: -210px; */
    /* top: -210px; */
    position: relative;
    width: 100%;
    min-height: 80vh;
    background-color: transparent;
    /* z-index: 50; */

}

.bg-referral::after {

    content: '';
    position: absolute;
    top: -50px;
    /* Initial position outside the banner */
    left: -50px;
    /* Initial position outside the banner */
    transform: translate(-50%, -80%);
    width: 1206px;
    height: 1307px;
    border-radius: 50%;
    background: linear-gradient(180deg, rgba(36, 0, 255, 0.5) 0%, rgba(4, 152, 224, 0.5) 0.01%, rgba(209, 74, 176, 0.5) 100%);
    filter: blur(100px);
    /* box-shadow: 0 0 100px 100px rgba(0, 0, 0, 0.5); */
    animation: moveCircleRef 10s linear infinite;
    z-index: -1;
}

@keyframes moveCircleRef {
    0% {
        top: -50px;
        /* Initial position outside the banner */
        left: -50px;
        /* Initial position outside the banner */
    }

    25% {
        top: -50px;
        /* Move upwards */
        left: calc(50% - 50px);
        /* Center horizontally */
    }

    50% {
        top: calc(50% - 50px);
        /* Center vertically */
        left: calc(100% - 50px);
        /* Move to the right */
    }

    75% {
        top: calc(100% - 50px);
        /* Move downwards */
        left: calc(50% - 50px);
        /* Center horizontally */
    }

    100% {
        top: calc(50% - 50px);
        /* Center vertically */
        left: -50px;
        /* Move to the left */
    }
}

@media only screen and (max-width: 995px) {
    .bg-referral {
        overflow: hidden;
    }
}