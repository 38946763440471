.handleFirstSectionOfIntro {
    /* background-image: url(/public/assets/introbg.png);
    background-repeat: no-repeat;
    background-position: center;
    height: 700px;
    margin-top: -110px; */
    position: relative;
    padding: 100px 0px 100px 0px;
    width: 100%;
    height: 700px;
    background-color: transparent;
    /* z-index: 1000; */
    /* overflow: scroll; */
}

.handleFirstSectionOfIntro::after {

    content: '';
    position: absolute;
    top: -50px;
    /* Initial position outside the banner */
    left: -50px;
    /* Initial position outside the banner */
    transform: translate(-50%, -50%);
    width: 1206px;
    height: 1307px;
    border-radius: 50%;
    background: linear-gradient(180deg, rgba(36, 0, 255, 0.5) 0%, rgba(4, 152, 224, 0.5) 0.01%, rgba(209, 74, 176, 0.5) 100%);
    filter: blur(100px);
    /* box-shadow: 0 0 100px 100px rgba(0, 0, 0, 0.5); */
    animation: moveCircle 10s linear infinite;
    z-index: -1;

    /* Move the circle to the background */
    ;
}

@keyframes moveCircle {
    0% {
        top: -50px;
        /* Initial position outside the banner */
        left: -50px;
        /* Initial position outside the banner */
    }

    25% {
        top: -50px;
        /* Move upwards */
        left: calc(50% - 50px);
        /* Center horizontally */
    }

    50% {
        top: calc(50% - 50px);
        /* Center vertically */
        left: calc(100% - 50px);
        /* Move to the right */
    }

    75% {
        top: calc(100% - 50px);
        /* Move downwards */
        left: calc(50% - 50px);
        /* Center horizontally */
    }

    100% {
        top: calc(50% - 50px);
        /* Center vertically */
        left: -50px;
        /* Move to the left */
    }
}

/* .handleFirstSectionOfIntro::-webkit-scrollbar {
    width: 0px;
}

.handleFirstSectionOfIntro::-webkit-scrollbar-track {
    background-color: transparent;
}

.handleFirstSectionOfIntro::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.handleFirstSectionOfIntro::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
} */


.sliding {
    position: absolute;
    top: 0;
    left: 0;
    animation: slideAnimation 3s infinite;
}

@keyframes slideAnimation {


    0%,
    100% {
        transform: translateX(0%);
        opacity: 15;
    }

    30%,
    70% {
        transform: translateX(50%);
        opacity: 0;
    }
}


.triDiv {
    width: 504px;
    height: 181px;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
    background: linear-gradient(96.47deg, #029EE3 13.62%, #C446AF 93.38%);
    border-radius: 40px;
}

.alignTextInfo {
    color: white;
    position: relative;
    top: -106px;
    left: 116px;
}

.handleIntroJoined {
    /* margin-top: -50px; */
    position: relative;
    top: -100px;
    left: 40px;
}

.handleMini {
    position: relative;
    left: 0px;
    z-index: -1;
}

.titleIntro {
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 61px;
    color: black;
}


.text-info-grad {
    background: linear-gradient(92.18deg, #0791DD -2.82%, #A63DAC 99.39%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
}


@media only screen and (max-width: 995px) {
    .handleFirstSectionOfIntro {

        overflow: hidden;
        height: 100% !important;
    }
}


@media only screen and (min-width: 765px) and (max-width: 995px) {
    .handleImg {
        width: 350px;
        height: auto;
    }

    .handleIntroJoined {
        margin-top: 50px;
    }

}

@media only screen and (min-width: 376px) and (max-width: 764px) {
    .handleImg {
        max-width: 100%;
        height: auto;
        margin-left: -25px;
        margin-bottom: 150px;
        position: relative;
        top: 50px;
    }

    .handleIntroJoined {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* margin-top: -50px; */
        position: relative;
        top: -100px;
        left: 10px;
    }

    .handleFirstSectionOfIntro {
        height: 700px;
        padding: 100px 0px 100px 0px;
        /* margin-top: -110px; */
    }

    .alignTextInfo {
        color: white;
        position: relative;
        top: -106px;
        left: 10px;
    }
}

@media only screen and (max-width: 375px) {
    .handleImg {
        max-width: 100%;
        height: auto;
        margin-left: -25px;
        margin-bottom: 150px;
        position: relative;
        top: 50px;
    }

    .alignTextInfo {
        color: white;
        position: relative;
        top: -106px;
        left: 10px;
    }


    .handleIntroJoined {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* margin-top: -50px; */
        position: relative;
        top: -100px;
        left: 40px;
    }

    .handleFirstSectionOfIntro {
        height: 700px;
        padding: 100px 0px 100px 0px;
        /* margin-top: -110px; */
    }
}

@media only screen and (min-width: 996px) and (max-width: 1200px) {
    .handleIntroJoined {
        margin-top: 50px;
    }

    .handleMini {
        position: relative;
        left: 0px;
        top: 30px;
        z-index: -1;
    }

    .handleImg {
        width: 500px;
        height: auto;
    }
}

@media only screen and (min-width: 1201px) {
    .handleIntroJoined {
        margin-top: 0px;
    }

    /* 
    .handleMini {
        position: relative;
        left: 0px;
        top: -80px;
        z-index: -1;
    } */
}