.titleofstake {
    font-style: normal;
    line-height: 81px;
    color: black;
    font-weight: 800;
}


.stakeBgRound {
    background: #D7EFFF;
    width: 150px;
    height: 150px;
    box-shadow: 0 0 90px #2979e2;
    /* padding: 10px; */
}

.lineShape {
    /* width: 88.5%; */
    color: rgba(22, 34, 93, 1);
    border-top: 1px solid #16225D;
    margin-left: 74px;
    margin-right: 74px;

}

.vertical-line {
    color: rgba(22, 34, 93, 1);
}

.brd {
    border-left: 1px solid #16225D;
}