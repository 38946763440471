.closeD {
    text-align: right;
}

.text-poly {
    color: #8247E5;
}

.btn-login {
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 5px;
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;

    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-login:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-login:focus {
    outline: none;
}

.btn-login.color-5 {
    background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
}

.handleAlignBtn {
    text-align: start;
}

.banner-button2 {
    background-image: linear-gradient(to right, #FF512F 0%, #db1528 51%, #FF512F 100%);
    margin: 10px;
    padding: 8px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border: none;
    font-weight: bold;
}

.fontText {
    padding: 10px;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.banner-button2:hover {
    background-image: linear-gradient(to right, #db1528 0%, #FF512F 51%, #db1528 100%);
}

/* .iconCloses {
    padding: 1px !important;
    background-color: red !important;
    border: none !important
} */

.iconClose {
    position: absolute !important;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    background: none !important;
    border: none !important;
    font-size: 25px !important;

}

/* swal */
.modal_class_success {
    background-color: #2D313A !important;
    color: white !important;
}

.modal_class_success .swal-icon::before {
    background-color: transparent !important;
}

.modal_class_success .swal-icon::after {
    background-color: transparent !important;
}

.swal-icon--success__hide-corners {
    display: none !important;
}

.closeD {
    text-align: right;
}

.closeBtn {
    display: flex;
    align-items: center;
    justify-content: end;
}

.iconClose {
    position: absolute !important;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    background: none !important;
    border: none !important;
    font-size: 25px !important;

}

.iconCloseR {
    position: absolute;
    top: 2px;
    right: 5px;
    width: 40px;
    height: 40px;
    background: none !important;
    border: none !important;
    font-size: 25px !important;

}


.banner-button2 {
    /* background-color: rgb(123, 123, 148) !important; */
    margin: 10px;
    padding: 5px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    font-weight: 500;
    border: none;
}

.font14 {
    font-size: 14px;
}

.otp-bg-color {
    background-color: #ff5421 !important;
}

.maskImg {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    animation: zoomAnimation 2s infinite alternate;
}

.maskImg:hover {
    transform: scale(1.2);
}

@keyframes zoomAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@media only screen and (max-width:600px) {
    .handleAlignBtn {
        text-align: end;
        margin-bottom: 8px;
    }

    .kyc-profile-btn {
        font-size: small;
    }
}

.mine-mineral {
    max-width: 200px;
}

.profile-cancel {
    min-width: 200px;
}

.button-logut {
    min-width: 200px;
}

@media only screen and (max-width:430px) {
    .button-logut {
        width: 200px;
        min-height: 45px;
    }

    .profile-cancel {
        min-width: 200px;
        min-height: 45px;
    }
}